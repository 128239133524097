import { EuiCode, EuiLink, EuiText } from "@inscopix/ideas-eui";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import {
  AnalysisTable,
  AnalysisTableGroup,
  Project,
  Task,
} from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import moment from "moment";
import { flyoutDrsFileInfoStyles } from "./FlyoutDrsFileInfo";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";

export interface DrsFileInfoAnalysisTableProps {
  task: Pick<Task, "id" | "created" | "status" | "userId">;
  project: Pick<Project, "key">;
  analysisTableGroup: Pick<AnalysisTableGroup, "id" | "name">;
  analysisTable: Pick<AnalysisTable, "id" | "name">;
}

export const DrsFileInfoAnalysisTable = ({
  task,
  project,
  analysisTableGroup,
  analysisTable,
}: DrsFileInfoAnalysisTableProps) => {
  const { routeMap } = useRoutes();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <EuiText size="s" css={flyoutDrsFileInfoStyles.descriptionListStyles}>
      <dl>
        <dt>Generated at:</dt>
        <dd>{moment(task.created).format("MMMM Do YYYY, h:mm:ss a")}</dd>
        <dt>Generated by:</dt>
        <dd>
          <EuiLink
            to={
              routeMap["PROJECT_ANALYSIS_TABLE_GROUP"].dynamicPath({
                tenantKey: currentTenant.key,
                analysisTableGroupId: analysisTableGroup.id,
                projectKey: project.key,
              }).path
            }
            data-test-subj="analysisTableLink"
          >
            {analysisTableGroup.name} ({analysisTable.name})
          </EuiLink>
        </dd>
        <dt>Task ID:</dt>
        <dd>
          <EuiCode>{task.id}</EuiCode>
        </dd>
        <dt>User:</dt>
        <dd>
          <UserAvatarRenderer
            userId={task.userId}
            displayName={true}
            size="s"
          />
        </dd>
        <dt>Log:</dt>
        <dd>
          <ButtonViewTaskLogs taskId={task.id} taskStatus={task.status} />
        </dd>
      </dl>
    </EuiText>
  );
};
