import axios, { AxiosResponse } from "axios";
import { ToolParamsGridRowDatum } from "components/ToolParamsGrid/ToolParamsGrid.types";
import {
  AnalysisTable,
  AnalysisTableRow,
  AnalysisTableRowFieldsFragment,
  Dataset,
  DatasetRecordingsTable,
  FileRecordingGroup,
  Project,
  Task,
  Tenant,
  ToolVersion,
} from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { JsonValue } from "type-fest";
import { getRequestHeaders } from "utils/getRequestHeaders";

type AnalysisTableRowDjangoFields = {
  id: DatasetRecordingsTable["id"];
  attachments: {
    // TODO ID-2566 remove uniq when root cause of duplicates is found
    datasets: Dataset["id"][];
    recordings: FileRecordingGroup["id"][];
  };
  data: ToolParamsGridRowDatum["params"];
  selections: JsonValue;
  groups: JsonValue;
  date_created: AnalysisTableRow["dateCreated"];
  deleted: boolean;
  tenant: Tenant["id"];
  project: Project["id"];
  table: AnalysisTable["id"];
  task: Task["id"] | null;
  metadatum_references: ToolParamsGridRowDatum["metadatumReferences"];
  tool_version: ToolVersion["id"];
};

const baseUrl = getEnvVar("URL_LIBRARY_ANALYSIS_TABLE_ROW");

/**
 * Formats a django response for analysis table row fields into a graphql cacheable field set
 * @param data Django response data
 * @returns formatted data
 */
const formatResponseData = (
  data: AnalysisTableRowDjangoFields,
): AnalysisTableRowFieldsFragment => ({
  __typename: "AnalysisTableRow",
  ...data,
  tenantId: data.tenant,
  tableId: data.table,
  dateCreated: data.date_created,
  taskId: data.task,
  data: data.data as JsonValue,
});

/**
 * Soft delete request function for deleting analysis table rows
 */
const SOFT_DELETE = async (rowId: AnalysisTableRow["id"]) => {
  const headers = await getRequestHeaders();
  return axios.delete<// response returns empty string (204/No Content)
  "">(baseUrl + "soft_delete/" + rowId + "/", {
    headers,
  });
};

/**
 * Patch request function for modifying analysis table rows
 */
const PATCH = async (
  rowId: AnalysisTableRow["id"],
  patch: Partial<Omit<AnalysisTableRowDjangoFields, "id" | "deleted" | "">>,
) => {
  const headers = await getRequestHeaders();
  const res = await axios.patch<
    Partial<Omit<AnalysisTableRow, "id" | "removed">>,
    AxiosResponse<AnalysisTableRowDjangoFields>
  >(baseUrl + rowId + "/", patch, {
    headers,
  });

  const returnResponse: AxiosResponse<AnalysisTableRowFieldsFragment> = {
    ...res,
    data: formatResponseData(res.data),
  };

  return returnResponse;
};

/**
 * Post request function for creating new analysis table rows
 */
const POST = async (data: {
  id: DatasetRecordingsTable["id"];
  tenant: Tenant["id"];
  project: Project["id"];
  table: DatasetRecordingsTable["id"];
  attachments: {
    // TODO ID-2566 remove uniq when root cause of duplicates is found
    datasets: Dataset["id"][];
    recordings: FileRecordingGroup["id"][];
  };
  selections: ToolParamsGridRowDatum["selections"];
  data: ToolParamsGridRowDatum["params"];
  metadatum_references: ToolParamsGridRowDatum["metadatumReferences"];
  tool_version: ToolVersion["id"];
}) => {
  const headers = await getRequestHeaders();
  const res = await axios.post<
    {
      id: DatasetRecordingsTable["id"];
      tenant: Tenant["id"];
      project: Project["id"];
      table: DatasetRecordingsTable["id"];
      attachments: {
        // TODO ID-2566 remove uniq when root cause of duplicates is found
        datasets: Dataset["id"][];
        recordings: FileRecordingGroup["id"][];
      };
      selections: ToolParamsGridRowDatum["selections"];
      data: ToolParamsGridRowDatum["params"];
    },
    AxiosResponse<AnalysisTableRowDjangoFields>
  >(baseUrl, data, {
    headers,
  });

  const returnResponse: AxiosResponse<AnalysisTableRowFieldsFragment> = {
    ...res,
    data: formatResponseData(res.data),
  };

  return returnResponse;
};

export const libraryAnalysisTableRowDjango = {
  soft_delete: SOFT_DELETE,
  post: POST,
  patch: PATCH,
};
