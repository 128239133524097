import { EuiSkeletonText } from "@inscopix/ideas-eui";
import assert from "assert";
import { AnalysisTable } from "components/AnalysisTable/AnalysisTable";
import {
  AnalysisTableIdentifiers,
  ToolParamsGridRowDatum,
  ToolSpec,
} from "components/ToolParamsGrid/ToolParamsGrid.types";
import { ToolParamsGridProvider } from "components/ToolParamsGrid/ToolParamsGridProvider";
import {
  AnalysisTableGroup,
  usePageProjectAnalysisQuery,
} from "graphql/_Types";
import { chain, clone, isUndefined } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";
import { AnalysisTableLayoutProvider } from "./AnalysisTableLayoutProvider";
import {
  isAnalysisTableRowAttachments,
  isToolPathParam,
} from "components/ToolParamsGrid/ToolParamsGrid.helpers";
import { isNonNull } from "utils/isNonNull";
import { roundToSignificant } from "utils/roundToSignificant";
import { syncMetadatumReferences } from "components/ToolParamsGrid/ToolParamsGridProvider.helpers";
import { isDefined } from "utils/isDefined";
import { useGetProjectFilesByFileIds } from "components/ToolParamsGrid/hooks/useGetProjectFilesByFileIds";
import { ToolParamsGridValueValidatorContext } from "components/ToolParamsGrid/ToolParamsGridValueValidatorContext";
import { ToolParamsGridRowDataProvider } from "components/ToolParamsGrid/ToolParamsGridRowDataProvider";
import { ErrorBoundary } from "@sentry/react";
import { PageProjectAnalysisError } from "./PageProjectAnalysisError";

interface PageProjectAnalysisProps {
  analysisTableGroupId: AnalysisTableGroup["id"];
}

export const PageProjectAnalysis = ({
  analysisTableGroupId,
}: PageProjectAnalysisProps) => {
  const [initialRowData, setInitialRowData] =
    useState<ToolParamsGridRowDatum[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [selectedTableId, setSelectedTableId] = useState<string>();
  const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();
  const { data, refetch } = usePageProjectAnalysisQuery({
    variables: {
      analysisTableGroupId,
      recordingGroupFileFilter: {
        dateAssignmentEnded: {
          isNull: true,
        },
      },
    },
    onError: setError,
    fetchPolicy: "network-only",
  });

  /**
   * Callback triggered when analysis table "tab" is selected
   */
  const handleClickTab = useCallback(
    async (tableId: string) => {
      if (selectedTableId !== tableId) {
        // Apply any changes made in the previous tab to the query data
        try {
          await refetch();
          setSelectedTableId(tableId);
          setInitialRowData(undefined);
        } catch (error) {
          setError(error as Error);
        }
      }
    },
    [refetch, selectedTableId],
  );

  const analysisTableGroup = data?.analysisTableGroup;
  const tool = analysisTableGroup?.tool;

  // Initialize the selectable analysis table ID state
  useEffect(() => {
    if (selectedTableId === undefined) {
      const newestTable = chain(analysisTableGroup?.analysisTables.nodes)
        .sortBy(({ dateCreated }) => dateCreated)
        .last()
        .value();

      setSelectedTableId(newestTable?.id);
    }
  }, [analysisTableGroup?.analysisTables.nodes, selectedTableId]);

  const analysisTables = useMemo(() => {
    return clone(analysisTableGroup?.analysisTables.nodes ?? [])
      .sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))
      .map((analysisTable) => {
        const toolSpec = analysisTable.toolVersion?.toolSpec;

        const toolVersion = analysisTable.toolVersion;

        const toolVersions =
          analysisTable.toolVersion?.compatibleVersions.nodes;

        if (
          isNullish(analysisTable) ||
          isNullish(analysisTable.name) ||
          isNullish(toolVersion) ||
          isNullish(toolSpec) ||
          isNullish(toolVersions)
        ) {
          return undefined;
        }

        return {
          ...analysisTable,
          toolVersion,
          name: analysisTable.name,
          identifiers:
            analysisTable.identifiers as AnalysisTableIdentifiers | null,
          toolSpec: toolSpec as ToolSpec,
          toolVersions,
          totalCredits: roundToSignificant(analysisTable?.usedCredits ?? 0),
        };
      })
      .filter(isDefined);
  }, [analysisTableGroup?.analysisTables.nodes]);

  const analysisTable = analysisTables.find(
    (table) => table.id === selectedTableId,
  );

  const recordings = useMemo(() => {
    const recordingNodes = analysisTable?.project?.datasets.nodes.flatMap(
      (dataset) => {
        return dataset?.datasetRecordingsTable?.recordingGroups.nodes.map(
          (recording) => ({ ...recording, dataset }),
        );
      },
    );
    if (recordingNodes === undefined) {
      return [];
    }

    return recordingNodes.map((recordingGroup) => {
      assert(isNonNullish(recordingGroup), "Expected recording to be defined");
      assert(
        isNonNullish(recordingGroup.number),
        'Expected "number" to exist for recording',
      );

      return {
        id: recordingGroup.id,
        number: recordingGroup.number,
        dateCreated: recordingGroup.dateCreated,
        datasetPrefix: recordingGroup.dataset.prefix,
      };
    });
  }, [analysisTable?.project?.datasets.nodes]);

  useEffect(() => {
    const pipelineTableRows = analysisTable?.analysisTableRows.nodes;

    if (
      analysisTable === undefined ||
      pipelineTableRows === undefined ||
      isDefined(initialRowData)
    ) {
      return;
    }

    const rowData = pipelineTableRows
      .map((row) => {
        if (row.toolVersion === null) {
          return undefined;
        }

        const { recordingsIds, datasetsIds } = (() => {
          const attachments = row?.attachments as unknown;
          // Read dataset and recording references from the row attachments for
          // executed rows
          if (row.taskId !== null) {
            if (isAnalysisTableRowAttachments(attachments)) {
              return {
                recordingsIds:
                  attachments.recordings.length > 0
                    ? attachments.recordings
                    : undefined,
                datasetsIds:
                  attachments.datasets.length > 0
                    ? attachments.datasets
                    : undefined,
              };
            }
            return { recordingsIds: undefined, datasetsIds: undefined };
          }

          // Recalculate dataset and recording references based on file IDs
          // stored in tool path param values
          return chain(analysisTable.toolSpec.params)
            .filter(isToolPathParam)
            .flatMap((param) => {
              const rowDatum = (row.data ??
                {}) as ToolParamsGridRowDatum["params"];
              const fileIds = rowDatum[param.key];
              return (fileIds ?? []) as string[];
            })
            .thru((fileIds) => {
              const { drsFilesFound: files } =
                getProjectFilesByFileIds(fileIds);
              const datasetsIds = chain(files)
                .flatMap((file) => file.datasets)
                .map((dataset) => dataset.id)
                .uniq()
                .value();
              const recordingsIds = chain(files)
                .flatMap((file) => file.recordings)
                .map((recording) => recording.id)
                .uniq()
                .value();
              return { datasetsIds, recordingsIds };
            })
            .value();
        })();

        const rowDatum: ToolParamsGridRowDatum = {
          id: row.id,
          task_id: row.task?.id,
          task_user_id: row.task?.userId,
          task_status: row.task?.status,
          task_date_created: row.task?.created,
          task_duration: row.task?.taskActivityByTaskId?.duration,
          task_compute_credit: row.task?.credits,
          task_cloned: row.task?.cloned,
          output_group_files: row.task?.outputGroup?.outputGroupFiles.nodes
            .map((node) => node.drsFile)
            .filter(isNonNull),
          params:
            (row.data as ToolParamsGridRowDatum["params"] | undefined) ?? {},
          recordings: recordingsIds,
          datasets: datasetsIds,
          selected: false,
          selections: row.selections as
            | ToolParamsGridRowDatum["selections"]
            | undefined,
          attachResults: row.activeAttachResults ?? true,
          metadatumReferences: (row.metadatumReferences ??
            {}) as ToolParamsGridRowDatum["metadatumReferences"],
          toolVersion: row.toolVersion,
        };

        return rowDatum;
      })
      .filter(isDefined);

    syncMetadatumReferences(rowData)
      .then(setInitialRowData)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [analysisTable, getProjectFilesByFileIds, initialRowData]);

  if (isLoading) {
    return <EuiSkeletonText lines={5} />;
  }

  if (
    error ||
    isNullish(analysisTableGroup) ||
    isNullish(analysisTable) ||
    isUndefined(initialRowData) ||
    isNullish(tool)
  ) {
    return <PageProjectAnalysisError />;
  }

  const project = analysisTable.project;
  assert(isNonNull(project), "Expected analysis table to belong to a project");

  return (
    <ErrorBoundary fallback={<PageProjectAnalysisError />}>
      <ToolParamsGridProvider
        // Specifying a key prop forces the provider to remount any time new
        // table is selected
        key={selectedTableId}
        project={project}
        recordings={recordings}
        analysisTableGroup={analysisTableGroup}
        analysisTable={analysisTable}
        toolSpec={analysisTable.toolSpec}
        toolId={tool.id}
        toolVersions={analysisTable.toolVersions}
      >
        <ToolParamsGridRowDataProvider
          projectId={project.id}
          initialRowData={initialRowData}
          analysisTable={analysisTable}
          toolSpec={analysisTable.toolSpec}
          projectKey={project.key}
        >
          <AnalysisTableLayoutProvider>
            <ToolParamsGridValueValidatorContext>
              <AnalysisTable
                group={analysisTableGroup}
                tables={analysisTables}
                selectedTableId={selectedTableId}
                setSelectedTableId={handleClickTab}
                tool={tool}
              />
            </ToolParamsGridValueValidatorContext>
          </AnalysisTableLayoutProvider>
        </ToolParamsGridRowDataProvider>
      </ToolParamsGridProvider>
    </ErrorBoundary>
  );
};
